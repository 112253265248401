.single-select-dropdown {
   border-radius: .25rem;
   margin-bottom: 16px;
   /* line-height: 38px; */
}

.dropdown-container p {
   font-size: 0.85rem;
   margin-bottom: .1rem;
   /*font-weight: bold;*/
}

p.error-message-label {
   color: #E60000;
   margin-left: 6px;
   font-size: 80%;
   font-weight: 400;
   margin-top: -12px;
   margin-bottom: 8px;
}

.error-dropdown .css-bg1rzq-control { /*.Select-control { */
   border-color: #E60000;
}