@import 'shared';

.App {
	min-height: 100vh;
}

header.App-header {
  background-color: $colorBlue;
  height: $headerHeight;
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  color: white;
  padding-left: 24px;
}
header.App-header h4 {
	margin: 0;
}

.page-content-container h4 {
	margin-bottom: 1rem;
}

.page-content-container {
	max-width: 1200px;
	background-color: white;
	margin: auto;
	padding: 24px;
	min-height: calc(100vh - #{$headerHeight})
}


.loading-view {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba($color: #000000, $alpha: 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
}


.date-picker-container {
	margin-bottom: 1rem;
}

.date-picker-container p.subtitle {
	font-size: 0.85rem;
	margin: 0;
}

.react-datepicker__input-container input {
	border-radius: 4px;
	outline: none;
	border: 1px solid rgb(204, 204, 204);
	height: 36px;
	padding: 12px;
	width: 180px;
}

.date-picker-container.error .react-datepicker__input-container input {
	border: 1px solid #E60000;
}



@media only screen and (max-width: 720px) {
	.page-content-container {
		padding: 20px 12px;
	}

	header.App-header {
		padding-left: 12px
	}

	header.App-header h4 {
		font-size: 1.3rem;
	}
 }