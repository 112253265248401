// Variables
$colorBlue: #01213d;
$colorRed: #C82333;
$headerHeight: 60px;


// Extend
// %message-shared {
//    border: 1px solid #ccc;
//    padding: 10px;
//    color: #333;
// }

// .message {
//    @extend %message-shared;
// }