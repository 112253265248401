.dialog-container {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.363)
}

.dialog {
	background-color: white;
	border-radius: 4px;
	border: 0.5px solid #e1e1e1;
	padding: 12px;
	width: 340px;
	max-width: 100%;
	color: #000;
}

.dialog p.title {
   margin-top: 6px;
   font-weight: 500;
   font-size: 1.2rem;
}

.dialog-buttons { /*button.bro-button.btn-danger*/
	display: flex;
	justify-content: flex-end;
}